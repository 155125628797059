import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X, Send, MessageCircle } from "lucide-react";

const FloatingContactForm = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitStatus, setSubmitStatus] = useState(null);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);
		setSubmitStatus(null);

		try {
			const response = await fetch("https://formspree.io/f/mqazjkjz", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formData),
			});

			if (response.ok) {
				setSubmitStatus('success');
				setFormData({ name: "", email: "", message: "" });
				// Ne fermez pas automatiquement le formulaire ici
			} else {
				setSubmitStatus('error');
			}
		} catch (error) {
			console.error('Error submitting form:', error);
			setSubmitStatus('error');
		}

		setIsSubmitting(false);
	};

	const handleClose = () => {
		setIsOpen(false);
		setSubmitStatus(null);
	};

	return (
		<>
			<motion.button
				className="fixed bottom-5 right-5 bg-black text-white p-4 rounded-full shadow-lg"
				style={{ zIndex: 5 }}
				whileHover={{
					scale: 1.1,
					boxShadow: "0px 0px 8px rgba(255,255,255,0.5)",
				}}
				whileTap={{ scale: 0.9 }}
				onClick={() => setIsOpen(true)}
			>
				<MessageCircle size={28} />
			</motion.button>

			<AnimatePresence>
				{isOpen && (
					<motion.div
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 50 }}
						className="fixed bottom-20 right-5 bg-gray-900 p-6 rounded-lg shadow-xl w-80"
						style={{ zIndex: 5 }}
					>
						<button
							onClick={handleClose}
							className="absolute top-2 right-2 text-gray-400 hover:text-white transition-colors"
							style={{ zIndex: 5 }}
						>
							<X size={20} />
						</button>
						{submitStatus !== 'success' ? (
							<>
								<h3 className="text-lg font-semibold mb-4 text-white">
									Contactez-moi
								</h3>
								<form onSubmit={handleSubmit} style={{ zIndex: 5 }}>
									<input
										type="text"
										name="name"
										value={formData.name}
										onChange={handleInputChange}
										placeholder="Votre nom"
										className="w-full mb-3 p-2 bg-gray-800 border border-gray-700 rounded text-white placeholder-gray-500 focus:outline-none focus:border-white transition-colors"
										required
										style={{ zIndex: 5 }}
									/>
									<input
										type="email"
										name="email"
										value={formData.email}
										onChange={handleInputChange}
										placeholder="Votre email"
										className="w-full mb-3 p-2 bg-gray-800 border border-gray-700 rounded text-white placeholder-gray-500 focus:outline-none focus:border-white transition-colors"
										required
										style={{ zIndex: 5 }}
									/>
									<textarea
										name="message"
										value={formData.message}
										onChange={handleInputChange}
										placeholder="Votre message"
										className="w-full mb-3 p-2 bg-gray-800 border border-gray-700 rounded text-white placeholder-gray-500 focus:outline-none focus:border-white transition-colors h-32"
										required
										style={{ zIndex: 5 }}
									></textarea>
									<motion.button
										type="submit"
										className="w-full bg-white text-black p-2 rounded font-semibold"
										style={{ zIndex: 5 }}
										whileHover={{
											scale: 1.05,
											backgroundColor: "#f0f0f0",
										}}
										whileTap={{ scale: 0.95 }}
										disabled={isSubmitting}
									>
										{isSubmitting ? (
											"Envoi en cours..."
										) : (
											<>
												<Send size={18} className="mr-2 inline" />
												Envoyer
											</>
										)}
									</motion.button>
									
									{submitStatus === 'error' && (
										<p className="mt-2 text-red-500">Erreur lors de l'envoi. Veuillez réessayer.</p>
									)}
								</form>
							</>
						) : (
							<motion.div
								initial={{ opacity: 0, scale: 0.8 }}
								animate={{ opacity: 1, scale: 1 }}
								className="text-center"
							>
								<h3 className="text-xl font-semibold mb-4 text-white">
									Merci pour votre message !
								</h3>
								<p className="text-gray-300 mb-4">
									Je vous répondrai dans les plus brefs délais.
								</p>
								<motion.button
									onClick={handleClose}
									className="bg-white text-black px-4 py-2 rounded font-semibold"
									whileHover={{
										scale: 1.05,
										backgroundColor: "#f0f0f0",
									}}
									whileTap={{ scale: 0.95 }}
								>
									Fermer
								</motion.button>
							</motion.div>
						)}
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};

export default FloatingContactForm;