const INFO = {
	main: {
		title: "Sami Boudechicha",
		name: "Sami Boudechicha",
		email: "sami.boudechicha@gmail.com",
		logo: "../logo.png",
		Zlogo: "./zephylogo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/",
		linkedin: "https://linkedin.com/",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Sami BOUDECHICHA : Software Engineer",
		description:
			"Développeur full-stack passionné, je transforme les défis en innovations. Du Web2 au Web3, de l'IoT au Machine Learning, je navigue dans l'univers tech avec agilité. Maîtrisant une palette de technologies modernes, je crée des applications robustes et évolutives. Toujours en quête d'apprentissage, je suis prêt à propulser vos idées vers de nouveaux horizons numériques. Construisons ensemble le futur !",
	},

	about: {
		title: "Sami Boudechicha : L'Ingénieur Logiciel Qui Repousse les Limites de l'Innovation 🚀",
		description: [
			{
				subtitle:
					"Du Concept à la Réalité : Un Parcours Technologique Exceptionnel 🌟",
				content:
					"Bienvenue dans mon univers où le code rencontre la créativité, et où chaque ligne de programmation ouvre de nouvelles possibilités. Je suis Sami Boudechicha, un ingénieur logiciel passionné qui transforme les idées novatrices en solutions numériques concrètes.",
			},
			{
				subtitle:
					"🎯 Mission : Propulser Votre Projet Vers l'Excellence",
				content:
					"Imaginez un développeur polyvalent, maîtrisant un large éventail de technologies. C'est ce que je vous offre : une expertise qui s'étend de l'IoT au Web 2.0, en passant par le Web3 et le Machine Learning, optimisant vos projets pour des performances exceptionnelles.",
			},
			{
				subtitle: "💡 Mon Atout Majeur : L'Adaptabilité Innovante",
				content:
					"Dans un écosystème technologique en perpétuelle évolution, je suis votre guide expert. Du JavaScript avancé au Python performant, en passant par le Rust innovant, je maîtrise les langages modernes pour créer des applications robustes et évolutives.",
			},
			{
				subtitle: "🌟 Pourquoi Me Choisir ?",
				content:
					"• Polyvalence Technique 🛠️ : De l'IoT aux applications web complexes, je navigue à travers diverses technologies avec aisance.\n\n• Innovation Constante 💼 : Mes solutions sont conçues pour optimiser la performance et garantir le succès.\n\n• Esprit d'Équipe Dynamique 🤝 : Je favorise une ambiance collaborative qui stimule la créativité de chacun.",
			},
			{
				subtitle: "Mon Parcours Professionnel 📈",
				content:
					"• GRDF : Développement de systèmes terrestres et interfaces RH innovantes.\n\n• Sopra Steria : Contribution à l'univers Dassault Aviation avec des solutions sur mesure.\n\n• Stealth Power (Texas) : Pilotage de projets IoT vers de nouveaux horizons technologiques.",
			},
			{
				subtitle: "🚀 Relevons le Défi Ensemble",
				content:
					"Vous avez un défi technique complexe ? C'est ma spécialité ! Collaborons pour créer la prochaine innovation technologique qui fera la différence !",
			},
			{
				subtitle: "Coordonnées de Contact 📬",
				content:
					"📍 Localisation : Gentilly, Île-de-France, France\n\n📧 Contact : sami.boudechicha@gmail.com | ☎️ +33 6 59 03 50 76 (WhatsApp)\n\n🌐 Portfolio : www.samiboudechicha.com",
			},
		],
	},
	// about: {
	// 	title: "Sami Boudechicha : Software Engineer, Catalyseur d'Innovation",
	// 	description: [
	// 		{
	// 			subtitle: "Mon Parcours",
	// 			content: "Salut ! Je suis Sami, un Software Engineer basé en Île-de-France, et mon terrain de jeu s'étend de l'IoT au Web3. Imaginez un développeur qui transforme les défis techniques en opportunités d'innovation - c'est moi !"
	// 		},
	// 		{
	// 			subtitle: "Mon Aventure Professionnelle",
	// 			content: "• GRDF : Modernisation de systèmes critiques et conception d'interfaces RH intuitives.\n• Sopra Steria : Création de solutions sur mesure pour Dassault Aviation.\n• Stealth Power (Texas) : Développement de projets IoT révolutionnaires.\n\nChaque étape a enrichi ma boîte à outils et affiné ma vision tech."
	// 		},
	// 		{
	// 			subtitle: "Ma Passion",
	// 			content: "Ce qui me motive vraiment, c'est d'utiliser la technologie pour résoudre des problèmes concrets. Mon projet de Master ? Un système de prédiction des pics de stress en entreprise. Zephy ? Une plateforme blockchain pour simplifier paiements et prêts. Pourquoi ? Parce que la tech doit améliorer notre quotidien et révolutionner nos interactions."
	// 		},
	// 		{
	// 			subtitle: "Mon Secret",
	// 			content: "Une curiosité insatiable qui me pousse à explorer sans cesse. Du C++ au Rust, en passant par le Machine Learning et la blockchain, je suis toujours en quête du prochain défi excitant."
	// 		},
	// 		{
	// 			subtitle: "Au-delà du Code",
	// 			content: "Explorateur dans l'âme, mes voyages, lectures et séances de sport nourrissent ma créativité et ma capacité à penser out of the box. C'est ce mix unique d'expertise technique et de perspective globale que j'apporte à chaque projet."
	// 		},
	// 		{
	// 			subtitle: "Relevons le Défi Ensemble",
	// 			content: "Vous avez un défi tech qui semble impossible ? C'est exactement ce qui m'intéresse ! Unissons nos forces pour créer la prochaine révolution technologique !"
	// 		}
	// 	]
	// },

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Zephy",
			description:
				"Application Web3 sur Optimism révolutionnant la DeFi : Paiements par liens et prêts instantanés. Utilise Alchemy pour simplifier l'expérience utilisateur, offrant une interface fluide et intuitive aux services financiers décentralisés. Autonomise les utilisateurs avec la blockchain, sans complexité technique.",
			logo: "./Zephy.png",
			linkText: "View Project",
			link: "https://github.com/SamiiBou/Zephy-smart-contract",
		},

		{
			title: "Aether",
			description:
				"Marché NFT sur Solana : Échangez rapidement des œuvres d'art numériques avec de faibles frais. Interface intuitive connectant artistes et collectionneurs dans un écosystème blockchain innovant. (En développement)",
			logo: "./Solana_logo.png",
			linkText: "View Project",
			link: "https://github.com/SamiiBou/nft-vitrine",
		},

		{
			title: "HelathCare",
			description:
				"HealthCare simplifie la gestion médicale pour docteurs et patients. Suivi personnalisé, prise de rendez-vous efficace et accès sécurisé aux dossiers. Optimisez la relation patient-praticien avec notre solution intuitive",
			logo: "./react.png",
			linkText: "View Project",
			link: "https://github.com/SamiiBou/my-medical-app-front-end",
		},

		{
			title: "Prédiction de Stress IA",
			description:
				"Projet Python utilisant le deep learning pour prédire l'état de stress d'un individu à partir de données synthétiques générées. Inclut scripts de génération de données et d'entraînement de modèle.",
			logo: "./python.png",
			linkText: "Voir le Projet",
			link: "https://github.com/SamiiBou/stress-prediction-ai",
		},

		{
			title: "Infinity Token",
			description:
				"Token personnalisé développé sur la blockchain Solana, offrant rapidité et faibles coûts de transaction. Explore les possibilités de la finance décentralisée. (En développement)",
			logo: "Rust.png",
			linkText: "View Project",
			link: "https://github.com/SamiiBou/infinity_token/",
		},
	],
};

export default INFO;
