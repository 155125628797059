import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";

const EducationTimeline = () => {
	const scrollRef = useRef(null);
	const [showScrollIndicator, setShowScrollIndicator] = useState(true);

	const educationBackground = [
		{
			year: "2021 - 2023",
			institution: "Université d'Aix-Marseille",
			degree: "Master MIAGE",
			details: "Ingénierie des Données et Décisions",
		},
		{
			year: "2020 - 2021",
			institution: "Université d'Aix-Marseille",
			degree: "Licence MIAGE",
			details: "Informatique appliquée à la gestion",
		},
		{
			year: "2019 - 2020",
			institution: "Université d'Aix-Marseille",
			degree: "Licence Informatique",
			details: "Formation générale",
		},
		{
			year: "2017 - 2019",
			institution: "Université Claude Bernard",
			degree: "Licence Math-Info",
			details: "Double spécialisation",
		},
	];

	useEffect(() => {
		const el = scrollRef.current;
		if (el) {
			const onWheel = (e) => {
				if (e.deltaY === 0) return;
				e.preventDefault();
				el.scrollTo({
					left: el.scrollLeft + e.deltaY,
					behavior: "smooth",
				});
			};
			el.addEventListener("wheel", onWheel);

			const onScroll = () => {
				if (el.scrollLeft > 20) {
					setShowScrollIndicator(false);
				} else {
					setShowScrollIndicator(true);
				}
			};
			el.addEventListener("scroll", onScroll);

			return () => {
				el.removeEventListener("wheel", onWheel);
				el.removeEventListener("scroll", onScroll);
			};
		}
	}, []);

	return (
		<div
			className="education-timeline bg-white p-8 font-sans overflow-hidden relative"
			style={{
				maxWidth: "1200px",
				margin: "0 auto",
				padding: "80px 20px",
			}}
		>
			<div
				ref={scrollRef}
				className="flex overflow-x-auto pb-8 -mx-4 scrollbar-hide relative"
				style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
			>
				{educationBackground.map((edu, index) => (
					<motion.div
						key={index}
						className="flex-none w-80 mx-4"
						initial={{ opacity: 0, x: 50 }}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 0.5, delay: index * 0.1 }}
					>
						<div className="p-6 h-full">
							<span className="text-sm font-semibold text-gray-500">
								{edu.year}
							</span>
							<h3 className="mt-2 text-2xl font-bold text-black">
								{edu.degree}
							</h3>
							<p className="mt-1 text-lg text-gray-700">
								{edu.institution}
							</p>
							<p className="mt-4 text-base text-gray-600">
								{edu.details}
							</p>
						</div>
					</motion.div>
				))}
			</div>
			<motion.div
				className="absolute bottom-4 right-4 w-12 h-12"
				initial={{ opacity: 1 }}
				animate={{ opacity: showScrollIndicator ? 1 : 0 }}
				transition={{ duration: 0.3 }}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					className="text-gray-300"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M9 5l7 7-7 7"
					/>
				</svg>
			</motion.div>
		</div>
	);
};

export default EducationTimeline;
