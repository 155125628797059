import React, { useState, useEffect } from 'react';
import './styles/TypingTitle.css';

const TypingTitle = () => {
    const [text, setText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [typingSpeed, setTypingSpeed] = useState(150);

    const titles = ['Full Stack', 'Web', 'Blockchain', 'IoT', 'ML'];
    const fixedPrefix = 'Développeur ';
    const specialTitle = 'Passionné Tech';

    useEffect(() => {
        const handleTyping = () => {
            const i = loopNum % (titles.length + 1);
            const fullText = i === titles.length ? specialTitle : titles[i];

            if (i === titles.length) {
                // Handling the special case "Passionné Tech"
                if (isDeleting) {
                    setText(text.slice(0, -1));
                    setTypingSpeed(200); // Much slower speed for deleting
                } else {
                    setText(fullText.substring(0, text.length + 1));
                    setTypingSpeed(150);
                }

                if (!isDeleting && text === fullText) {
                    setTimeout(() => setIsDeleting(true), 2000); // Longer pause before deleting
                } else if (isDeleting && text === '') {
                    setIsDeleting(false);
                    setLoopNum(loopNum + 1);
                    setTypingSpeed(1000); // Pause before starting the next cycle
                }
            } else {
                // Handling regular titles
                if (isDeleting) {
                    setText(text.slice(0, -1));
                    setTypingSpeed(100); // Slower deletion for regular titles
                } else {
                    setText(fullText.substring(0, text.length + 1));
                    setTypingSpeed(150);
                }

                if (!isDeleting && text === fullText) {
                    setTimeout(() => setIsDeleting(true), 1500); // Longer pause before deleting
                } else if (isDeleting && text === '') {
                    setIsDeleting(false);
                    setLoopNum(loopNum + 1);
                    setTypingSpeed(500); // Short pause before the next title
                }
            }
        };

        const timer = setTimeout(handleTyping, typingSpeed);
        return () => clearTimeout(timer);
    }, [text, isDeleting, loopNum, typingSpeed]);

    return (
        <div className="title homepage-title">
            Sami BOUDECHICHA :<br />
            {loopNum % (titles.length + 1) === titles.length ? (
                <span className="typed-text">{text}</span>
            ) : (
                <>
                    <span className="fixed-text">{fixedPrefix}</span>
                    <span className="typed-text">{text}</span>
                </>
            )}
            {loopNum % (titles.length + 1) === titles.length && text === specialTitle && (
                <span className="heart"></span>
            )}
            <span className="cursor"></span>
        </div>
    );
};

export default TypingTitle;