import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "./styles/navBar.css";

const NavBar = () => {
	const [activeSection, setActiveSection] = useState("");

	const sections = [
		{ name: "Accueil", id: "homepage-first-area" },
		{ name: "Projets", id: "homepage-projects" },
		{ name: "Expérience", id: "homepage-works" },
		{ name: "Parcours", id: "homepage-education" },
		{ name: "Compétences", id: "homepage-skills" },
	];

	useEffect(() => {
		const handleScroll = () => {
			const currentSection = sections.find((section) => {
				const element = document.getElementById(section.id);
				if (element) {
					const rect = element.getBoundingClientRect();
					return rect.top <= 50 && rect.bottom >= 50;
				}
				return false;
			});

			if (currentSection) {
				setActiveSection(currentSection.id);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<div className="nav-container">
			<nav className="navbar">
				<div className="nav-background">
					<ul className="nav-list">
						{sections.map((section) => (
							<li
								key={section.id}
								className={`nav-item ${
									activeSection === section.id ? "active" : ""
								}`}
							>
								<Link
									to={section.id}
									smooth={true}
									duration={500}
									spy={true}
									offset={-50}
									onSetActive={() =>
										setActiveSection(section.id)
									}
									className={
										activeSection === section.id
											? "active"
											: ""
									}
								>
									{section.name}
								</Link>
							</li>
						))}
					</ul>
				</div>
			</nav>
		</div>
	);
};

export default NavBar;
