import React from "react";

function article_1() {
	return {
		date: "1 Septembre 2023",
		title: "Prédiction et gestion basées sur les données des pics de charge de travail et de stress pour améliorer le bien-être et la productivité des employés dans l'entreprise.",
		description: 
    "Découvrez comment la prédiction et la gestion des pics de charge de travail et de stress peuvent améliorer le bien-être et la productivité des employés dans l'entreprise.",
  keywords: [
    "Bien-être au travail",
    "Gestion du stress",
    "Productivité",
    "Intelligence artificielle",
    "Modèles prédictifs",
    "Charge de travail",
  ],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Content of article 1</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1];

export default myArticles;
