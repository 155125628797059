import "./styles/timelineTabbedSkills.css";
import FuturisticSkillTree from "./FuturisticSkillTree";
import React, { useState } from "react";
import { Award, Code, Database, Cpu } from "lucide-react";

const TimelineTabbedSkills = () => {
	const [activeTab, setActiveTab] = useState("skills");
	const [activeCategory, setActiveCategory] = useState(
		"Languages & Frameworks"
	);

	const skills = [
		{
			category: "Languages & Frameworks",
			icon: <Code size={24} />,
			items: [
				"JavaScript",
				"Python",
				"R",
				"PHP",
				"Java",
				"C#",
				"C++",
				"Rust",
				"React",
				"Angular",
				"NextJs",
				"Node.js",
				"Spring",
			],
		},
		{
			category: "Databases & Cloud",
			icon: <Database size={24} />,
			items: [
				"PostgreSQL",
				"T-SQL",
				"NoSQL",
				"MongoDB",
				"AWS",
				"Azure",
				"Google Cloud",
			],
		},
		{
			category: "Blockchain & Tools",
			icon: <Cpu size={24} />,
			items: [
				"Solana",
				"Anchor",
				"Solidity",
				"Optimism",
				"Alchemy",
				"Git",
				"Docker",
				"Kubernetes",
				"Terraform",
			],
		},
	];

	const renderSkills = () => <FuturisticSkillTree skills={skills} />;

	return (
		<div className="timelinex-tabbed-skills">
			{activeTab === "skills" && renderSkills()}
		</div>
	);
};

export default TimelineTabbedSkills;
