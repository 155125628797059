import React, { useState, useEffect, useRef } from "react";
import { Code, Database, Cpu } from "lucide-react";

const FuturisticSkillTree = () => {
	const [activeSkill, setActiveSkill] = useState(null);
	const containerRef = useRef(null);
	const [scale, setScale] = useState(1);

	const skills = [
		{
			category: "Dev",
			icon: <Code size={24} />,
			items: [
				"JavaScript",
				"Python",
				"R",
				"PHP",
				"Java",
				"C#",
				"C++",
				"Rust",
				"React",
				"Angular",
				"NextJs",
				"Node.js",
				"Spring",
			],
		},
		{
			category: "Data",
			icon: <Database size={24} />,
			items: [
				"PostgreSQL",
				"T-SQL",
				"NoSQL",
				"MongoDB",
				"AWS",
				"Azure",
				"Google Cloud",
			],
		},
		{
			category: "Blockchain",
			icon: <Cpu size={24} />,
			items: ["Solana", "Anchor", "Solidity", "Optimism", "Alchemy"],
		},
	];

	useEffect(() => {
		const updateScale = () => {
			if (containerRef.current) {
				const { width, height } =
					containerRef.current.getBoundingClientRect();
				const minDimension = Math.min(width, height);
				setScale(minDimension / 800);
			}
		};

		updateScale();
		window.addEventListener("resize", updateScale);
		return () => window.removeEventListener("resize", updateScale);
	}, []);

	const handleMouseEnter = (skill) => {
		setActiveSkill(skill);
	};

	const handleMouseLeave = () => {
		setActiveSkill(null);
	};

	return (
		<div
			ref={containerRef}
			className="futuristic-skill-tree bg-white font-sans overflow-hidden relative"
			style={{
				width: "100%",
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div
				className="instructions"
				style={{
					fontSize: "16px",
					color: "#000",
					animation: "blink 2s infinite",
					marginBottom: "20px",
					textAlign: "center",
				}}
			>
				Survolez les cercles pour explorer les compétences
			</div>
			<svg
				viewBox="0 0 800 600"
				className="skill-tree-svg"
				style={{
					width: `${800 * scale}px`,
					height: `${600 * scale}px`,
					maxWidth: "100%",
					maxHeight: "70vh",
				}}
			>
				{skills.map((category, index) => {
					const angle =
						(index / skills.length) * 2 * Math.PI - Math.PI / 2;
					const x = 400 + 250 * Math.cos(angle);
					const y = 300 + 250 * Math.sin(angle);
					return (
						<g key={category.category}>
							<line
								x1="400"
								y1="300"
								x2={x}
								y2={y}
								stroke="#000"
								strokeWidth="1"
								className="skill-line"
							/>
							<g
								transform={`translate(${x}, ${y})`}
								className="skill-node"
								onMouseEnter={() => handleMouseEnter(category)}
								onMouseLeave={handleMouseLeave}
							>
								<circle
									r="40"
									fill="#fff"
									stroke="#000"
									strokeWidth="2"
									className="skill-circle"
								/>
								<foreignObject
									x="-20"
									y="-20"
									width="40"
									height="40"
								>
									<div className="skill-icon text-black">
										{category.icon}
									</div>
								</foreignObject>
								<text
									x="0"
									y="60"
									textAnchor="middle"
									className="skill-label"
									style={{ fontSize: "14px", fill: "#000" }}
								>
									{category.category}
								</text>
							</g>
						</g>
					);
				})}

				{activeSkill && (
					<g className="skill-details">
						<circle
							cx="400"
							cy="300"
							r="180"
							fill="rgba(0, 0, 0, 0.05)"
							stroke="#000"
							strokeWidth="2"
						/>
						{activeSkill.items.map((item, index) => {
							const itemAngle =
								(index / activeSkill.items.length) *
									2 *
									Math.PI -
								Math.PI / 2;
							const itemX = 400 + 160 * Math.cos(itemAngle);
							const itemY = 300 + 160 * Math.sin(itemAngle);
							return (
								<g
									key={item}
									transform={`translate(${itemX}, ${itemY})`}
								>
									<circle
										r="30"
										fill="#fff"
										stroke="#000"
										strokeWidth="1"
									/>
									<text
										textAnchor="middle"
										alignmentBaseline="middle"
										fill="#000"
										fontSize="12"
									>
										{item}
									</text>
								</g>
							);
						})}
					</g>
				)}
			</svg>
			<style jsx>{`
				@keyframes blink {
					0% {
						opacity: 1;
					}
					50% {
						opacity: 0.5;
					}
					100% {
						opacity: 1;
					}
				}
			`}</style>
		</div>
	);
};

export default FuturisticSkillTree;
