import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Award } from 'lucide-react';

const certifications = [
  { name: "EXIN Agile Scrum Foundation", year: "2023" },
  { name: "ITIL Foundation Certificate in IT Service Management", year: "2022" },
  { name: "PRINCE 2 Foundation Certificate in Project Management", year: "2022" },
];

const participations = [
  { name: "ETH Global Participation", year: "2024" },
  { name: "Hackathon Bouygues", year: "2023" }
];

const HoverAchievementsButton = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="fixed top-0 right-0 flex items-center justify-end" style={{ top: '0' }}>
      <motion.div
        className="bg-black text-white rounded-l-lg shadow-lg overflow-hidden flex flex-row"
        initial={{ width: "48px" }}
        animate={{ 
          width: isHovered ? "300px" : "48px",
          backgroundColor: isHovered ? "#ffffff" : "#000000",
          color: isHovered ? "#000000" : "#ffffff"
        }}
        transition={{ duration: 0.3 }}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        style={{ transformOrigin: "right" }}
      >
        <motion.div 
          className="p-4 overflow-y-auto max-h-64 flex-grow"
          initial={{ opacity: 0 }}
          animate={{ opacity: isHovered ? 1 : 0 }}
          transition={{ duration: 0.2, delay: isHovered ? 0.1 : 0 }}
        >
          <div className="mb-4">
            <h3 className="font-semibold mb-1">Certifications</h3>
            <ul className="list-disc list-inside">
              {certifications.map((cert, index) => (
                <li key={index} className="text-sm mb-1">
                  {cert.name} ({cert.year})
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="font-semibold mb-1">Participations</h3>
            <ul className="list-disc list-inside">
              {participations.map((part, index) => (
                <li key={index} className="text-sm mb-1">
                  {part.name} ({part.year})
                </li>
              ))}
            </ul>
          </div>
        </motion.div>
        <div className="flex-shrink-0 flex items-center justify-center" style={{ width: "48px", position :isHovered ? "" : "absolute", top : isHovered ? "0%" : "42%" }}>
          <Award size={24} />
        </div>
      </motion.div>
    </div>
  );
};

export default HoverAchievementsButton;