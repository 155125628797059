import React from "react";
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
} from "@mui/lab";
import { Typography, Box } from "@mui/material";

const WorkExperience = ({ company, position, duration, location }) => (
	<TimelineItem>
		<TimelineSeparator>
			<TimelineDot style={{ backgroundColor: "black" }} />
			<TimelineConnector />
		</TimelineSeparator>
		<TimelineContent>
			<Typography variant="h6" component="span">
				{company}
			</Typography>
			<Typography>{position}</Typography>
			<Typography variant="body2" color="text.secondary">
				{duration} | {location}
			</Typography>
		</TimelineContent>
	</TimelineItem>
);

const Works = () => {
	const workExperiences = [
		{
			company: "STEALTH POWER",
			position: "Software Engineer",
			duration: "MAI 2024 - NOVEMBRE 2024",
			location: "Austin, Texas, États-Unis",
		},
		{
			company: "SOPRA STERIA",
			position: "Développeur Full Stack (CDD)",
			duration: "DECEMBRE 2023 - AVRIL 2024",
			location: "Aix-en-provence, France",
		},
		{
			company: "GRDF (Groupe ENGIE)",
			position: "Développeur Full Stack (Alternance)",
			duration: "SEPTEMBRE 2021 - SEPTEMBRE 2023",
			location: "Aix-en-provence, France",
		},
		{
			company: "CUTTI",
			position: "Développeur Rust (Stage)",
			duration: "AVRIL 2021 - JUIN 2021",
			location: "Lille, France",
		},
	];

	return (
		<Box sx={{ maxWidth: "800px", margin: "0 auto", padding: "80px 20px" }}>
			<Typography
				variant="h2"
				component="h1"
				sx={{
					fontFamily: "'Raleway', sans-serif",
					fontWeight: 700,
					textTransform: "uppercase",
					marginBottom: "60px",
					"& .light": {
						display: "block",
						color: "#888",
						fontWeight: 300,
					},
					"& .bold": {
						display: "block",
						color: "#333",
						letterSpacing: "-0.03em",
					},
				}}
			></Typography>
			<Timeline position="alternate">
				{workExperiences.map((work, index) => (
					<WorkExperience key={index} {...work} />
				))}
			</Timeline>
		</Box>
	);
};

export default Works;
